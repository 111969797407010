<template>
  <div>
    <el-config-provider :locale="locale">
      <router-view></router-view>
    </el-config-provider>
  </div>
</template>

<script>
import { ElConfigProvider } from 'element-plus'
import elementLangEn from 'element-plus/dist/locale/en.mjs'
import elementLangZhTw from 'element-plus/dist/locale/zh-tw.mjs'
export default {
  name: 'vue-App',
  components: {
    ElConfigProvider,
  },
  data(){
    return {
      locale: elementLangZhTw
    }
  },
  watch: {
    '$i18n.locale': function(lang){
      switch(lang){
        case 'en-US':
        case 'en_us':
          this.locale = elementLangEn
          break
        case 'zh-TW':
        case 'zh_tw':
          this.locale = elementLangZhTw
          break
        case 'default':
          this.locale = elementLangZhTw
          break
      }
    }
  }
}
</script>

<style lang="scss">
</style>
